.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.swal2-popup {
  width: 20em !important;
}

.swal2-html-container {
  font-size: 13px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dashboard-chart>div:first-child {
  width: 100% !important;
}

.dashboard-chart>div>canvas {
  width: 100% !important;
}

.dashboard-chart-scatter>div:first-child {
  width: 100% !important;
}

.dashboard-chart-scatter>div>svg {
  width: 100% !important;
}

.dashboard-chart-scatter-dashBoard-1>div:first-child{
  width: 100% !important;
}
.dashboard-chart-scatter-dashBoard-1>div>div{
  width: 100% !important;
}
.dashboard-chart-scatter-dashBoard-1>div>div>svg{
  width: 100% !important;
}

.dashboard-chart-scatter-dashBoard>div:first-child {
  width: 100% !important;
}

.dashboard-chart-scatter-dashBoard>div>div {
  width: 100% !important;
}

.ApexChart>div>div>svg {
  width: 100% !important;
}

.apexcharts-legend {
  display: none !important;
}

.nft-project-headrer-text {
  text-align: left;
  font: normal normal bold 30px/34px Helvetica Neue;
  letter-spacing: 0px;
  color: #1F1F1F;
  opacity: 1;
}

.nft-project-headrer-id-button {
  text-align: left;
  font: normal normal bold 15px/17px Helvetica Neue;
  letter-spacing: 0px;
  color: #6C6C6C;
  opacity: 1;
}

.nft-project-headrer-small-text {
  text-align: left;
  font: normal normal normal 14px/14px Helvetica Neue;
  letter-spacing: 0px;
  color: #6C6C6C;
  opacity: 1;
}

.nft-project-headrer-text-contact-address{
  text-align: left;
font: normal normal bold 15px/17px Helvetica Neue;
letter-spacing: 0px;
color: #0B0F15;
}

.nft-project-headrer-text-address-id{
  text-align: left;
font: normal normal normal 15px/17px Helvetica Neue;
letter-spacing: 0px;
color: #004ECB;
}

.nft-project-headrer-text-date{
  text-align: left;
font: normal normal normal 15px/17px Helvetica Neue;
letter-spacing: 0px;
color: #6C6C6C;
opacity: 1;
}

.nft-project-headrer-text-day-count{
  text-align: left;
font: normal normal bold 15px/17px Helvetica Neue;
letter-spacing: 0px;
color: #6C6C6C;
opacity: 1;
}

.nft-project-headrer-text-social{
  text-align: left;
font: normal normal normal 11px/12px Helvetica Neue;
letter-spacing: 0px;
color: #1B9DFC;
opacity: 1;
}

.nft-project-headrer-text-group-button{
  text-align: left;
font: normal normal normal 15px/17px Helvetica Neue;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}

.grid-container {
  display: grid;
  grid: 100% / auto 150px;
  grid-gap: 10px;
  padding: 10px;
}

.grid-container > div {
  padding: 20px 0;
}
.table-scroll{
  overflow: initial;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}