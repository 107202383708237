.abc {
  position: relative;
}
.abc .MuiCircularProgress-indeterminate{
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 15%;
  z-index: 99;
}

.side-bar-link {
  text-decoration: none;
  color: #797979;
  margin-left: 13px;
}

.side-bar-link-selected {
  text-decoration: none;
  color: #fff;
  margin-left: 13px;
}

.form-center {
  margin: auto;
  width: 40%;
  padding: 10px;
}

.text-size-input {
  width: 500px;
}

.app-bar {
  background-color: #fff !important;
}

.nab-bar-arrow {
  color: #fff
}

.app-bar-dots {
  color: black;
}

.login-section {
  height: 100vh;
}

.login-card {
  width: 100%;
  max-width: 650px;
  box-shadow: 15px 15px 52px #afafaf !important;
}

.login-logo {
  height: 60px;
  object-fit: contain;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.kull-form-control {
  border: 1px solid #ccc;
  width: 100%;
  height: 36px;
  border-radius: 6px;
  padding: 6px 15px;
  box-shadow: none !important;
  outline: none !important;
}

.pos-img-select {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.pos-img-select input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.pos-img-select .uploadImgShow {
  width: 98px;
  height: 98px;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 6px;
}

.add-height input {
  height: 40px;
}